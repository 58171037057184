



















 import { Page, Sort, Filter, Edit, Toolbar,CommandColumn } from "@syncfusion/ej2-vue-grids";
import { Component, Vue ,Ref} from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import { Enums, TrackStatus } from "@/Helper/Enum";
import {createElement} from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import { WebApi } from "@/Helper/WebApi";
Vue.use(GridPlugin);
let TrackTypeTypeInstance:any = null;
@Component({
     components: {
  },
  provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar,CommandColumn]
  }
})
export default class Student extends Vue {
  @Ref() readonly MyDataGrid!: any
  dataManager = MyGridOption.getDataManager('TeacherAssignmentList');
  filterOptions = {    type: 'Menu'};
  wrapSettings= { wrapMode: 'Both ' };
  commands= [{ buttonOption: { content: 'Approve', cssClass: 'e-flat' } },
  { buttonOption: { content: 'Reject', cssClass: 'e-flat' } }]
TrackingTypeFilter={
 ui: {
create: function (args:any) {
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                TrackTypeTypeInstance = new DropDownList({
                dataSource: new DataManager(Enums.TrackStatus),
                fields: { text: 'data', value: 'value' },
                placeholder: 'Select a value',
                popupHeight: '200px'
            });
            TrackTypeTypeInstance.appendTo(flValInput);
            },
            write: function (args:any) {
                TrackTypeTypeInstance.value = args.filteredValue;
            },
            read: function (args:any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, TrackTypeTypeInstance.value);
            }
 }
    }
  StatusString(field:any, data:any, column:any){
 return TrackStatus[data[field]];
  }
    MediaLink(field: any, data: any, column: any) {
        var url = 'https://lt.tinker.ly/api/v1/GetFileStream/1/'+data[field];
       // var url = 'https://alphalt.tinker.ly/api/v1/GetFileStream/1/' + data[field];
    console.log(data[field]);
    if(data[field] != null && data[field].length>0)
       // return "<a href='https://lt.tinker.ly/api/v1/GetFileStream/1/" + data[field] + "' target='_blank'>Download</a>";
        return "<a href='"+url+"' target='_blank'>Download</a>";
    else
    return "";
  }
   commandClick(args:any) {
     if(args.rowData.TrackStatus!=0)
     {
        this.$bus.$emit('testing_call',{data:"Action will work for only Pending Assignment"});
        return;
     }
     var final = false;
     if(args.commandColumn.buttonOption.content=="Approve")
      final = true;
      WebApi.UpdateAssignmentStatus(args.rowData.Id,final+"").then(d=>{
 this.$store.commit("ChangeLoading", false);
 this.MyDataGrid.ej2Instances.refresh()
      });
   }
}
